import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { MorePosts } from '../more-post/more-post';
import { RelatedPosts } from '../related-posts/related-posts';
export const AdditionalPosts: React.FC<{
  tags: string[];
  slug: string;
}> = ({ tags, slug }) => {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        sort: { fields: frontmatter___date, order: DESC }
        filter: { frontmatter: {} }
      ) {
        edges {
          node {
            frontmatter {
              slug
              title
              tags
              date(formatString: "MMMM DD, YYYY")
              featuredImage {
                childImageSharp {
                  fixed(width: 350, height: 200) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const postData = data.allMarkdownRemark.edges.map(
    (x: any, i: number) => ({
      id: i,
      slug: x.node.frontmatter.slug,
      date: x.node.frontmatter.date,
      tags: x.node.frontmatter.tags,
      title: x.node.frontmatter.title,
      featuredImage:
        x.node.frontmatter.featuredImage?.childImageSharp.fixed,
    }),
  );

  const currSlugPosition = postData.filter(
    (x: any) => x.slug === slug,
  )[0].id;

  const prev = postData[currSlugPosition + 1];
  const next = postData[currSlugPosition - 1];

  return (
    <>
      <RelatedPosts tags={tags} slug={slug} postData={postData} />
      <MorePosts prev={prev} next={next} />
    </>
  );
};
