import React from 'react';
import { SocialShare } from './social-share/social-share';
import { container } from './social-shares.module.css';

export const SocialShares: React.FC<{
  title: string;
  slug: string;
}> = ({ title, slug }) => {
  const socialShares = [
    'twitter',
    'facebook',
    'linkedin',
    'email',
    'reddit',
    'whatsapp',
    'telegram',
    'line',
    'tumblr',
    'pocket',
    'instapaper',
    'workplace',
    'livejournal',
  ];
  return (
    <>
      {socialShares.map((type) => (
        <div key={type} className={container}>
          <SocialShare title={title} slug={slug} type={type} />
        </div>
      ))}
    </>
  );
};
