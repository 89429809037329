import {
  faLongArrowAltLeft,
  faLongArrowAltRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';
import React from 'react';
import { container, left, title } from './more-post.module.css';

export const MorePosts: React.FC<{ prev: any; next: any }> = ({
  prev,
  next,
}) => {
  return (
    <div className={container}>
      <div>
        {prev !== undefined ? (
          <Link to={prev.slug}>
            <div className={`${title} ${left}`}>
              <FontAwesomeIcon
                style={{ marginRight: '5px' }}
                icon={faLongArrowAltLeft}
              />
              <span>previous post</span>
            </div>
            <div>{prev.title}</div>
          </Link>
        ) : null}
      </div>
      <div>
        {next !== undefined ? (
          <Link to={next.slug}>
            <div className={`${title} ${left}`}>
              <span>next post</span>
              <FontAwesomeIcon
                style={{ marginLeft: '5px' }}
                icon={faLongArrowAltRight}
              />
            </div>
            <div>{next.title}</div>
          </Link>
        ) : null}
      </div>
    </div>
  );
};
