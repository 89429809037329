import React from 'react';
import { graphql, Link } from 'gatsby';
import { layout } from '../pages/index.module.css';
import SEO from '../components/seo/seo';
import {
  blogTemplate,
  blogMain,
  blogTitle,
  blogTags,
  blogDate,
  blogPostContent,
  blogPostShareContainer,
  blogPostShareText,
  blogPostShareButtons,
} from './blog-template.module.css';
import { Header } from '../components/header/header';
import { Logo } from '../components/logo/logo';
import { SocialShares } from '../components/social-shares/social-shares';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AdditionalPosts } from '../components/additional-posts/additional-posts';
import { defineCustomElements as deckDeckGoHighlightElement } from '@deckdeckgo/highlight-code/dist/loader';
deckDeckGoHighlightElement();

export default function Template({ data }) {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;
  const tags = frontmatter.tags.map((tag, i) => (
    <Link key={i} className="blog-tag" to={`/tags/${tag}`}>
      {tag},{' '}
    </Link>
  ));
  return (
    <div className={layout}>
      <SEO
        title={frontmatter.title}
        description={
          html
            .slice(0, 40)
            .replace(/<p>/g, '')
            .replace('</p>', '')
            .replace(/<code>/g, '')
            .replace('</code>', '') + '...'
        }
        tags={frontmatter.tags}
      />
      <Header>
        <Logo />
      </Header>
      <div className={blogTemplate}>
        <div className={blogMain}>
          <h2 className={blogTitle}>{frontmatter.title}</h2>
          <div className={blogTags}>
            <div>Tags: {tags}</div>
          </div>
          <div className={blogDate}>
            <div style={{ marginRight: '5px' }}>
              <FontAwesomeIcon color="#929292" icon={faClock} />
            </div>
            <div>{frontmatter.date}</div>
          </div>

          <div
            className={blogPostContent}
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
        <div className={blogPostShareContainer}>
          <div className={blogPostShareText}>please share</div>
          <div className={blogPostShareButtons}>
            <SocialShares
              title={frontmatter.title}
              slug={frontmatter.slug}
            />
          </div>
        </div>
        <AdditionalPosts
          tags={frontmatter.tags}
          slug={frontmatter.slug}
        />
      </div>
    </div>
  );
}
export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        tags
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 340) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
