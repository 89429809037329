import React from 'react';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  HatenaIcon,
  HatenaShareButton,
  InstapaperIcon,
  InstapaperShareButton,
  LineIcon,
  LineShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  LivejournalIcon,
  LivejournalShareButton,
  MailruIcon,
  MailruShareButton,
  PocketIcon,
  PocketShareButton,
  RedditIcon,
  RedditShareButton,
  TelegramIcon,
  TelegramShareButton,
  TumblrIcon,
  TumblrShareButton,
  TwitterIcon,
  TwitterShareButton,
  ViberIcon,
  ViberShareButton,
  WeiboIcon,
  WeiboShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  WorkplaceIcon,
  WorkplaceShareButton,
} from 'react-share';
export const SocialShare: React.FC<{
  title: string;
  slug: string;
  type: string;
}> = ({ title, slug, type }) => {
  const shareButtonStyle = {
    height: '38px',
    width: '38px',
    backgroundColor: 'transparent',
    borderRadius: '50%',
    display: 'inline-block',
    border: '1px solid #e9e9e9',
    outline: 'none',
  };
  const iconStyle = {
    fill: 'transparent',
    border: '5px solid gray',
    outline: 'red',
  };
  const iconSize = 32;

  switch (type) {
    case 'twitter': {
      return (
        <>
          <TwitterShareButton
            url={`https://rasmusolsson.dev${slug}`}
            title={title}
            style={shareButtonStyle}
          >
            <TwitterIcon
              size={iconSize}
              round
              iconFillColor="#bbb"
              bgStyle={iconStyle}
              borderRadius={1}
            ></TwitterIcon>
          </TwitterShareButton>
        </>
      );
    }
    case 'facebook': {
      return (
        <FacebookShareButton
          url={`https://rasmusolsson.dev${slug}`}
          title={title}
          style={shareButtonStyle}
        >
          <FacebookIcon
            size={iconSize}
            round
            iconFillColor="#bbb"
            bgStyle={iconStyle}
            borderRadius={1}
          ></FacebookIcon>
        </FacebookShareButton>
      );
    }
    case 'linkedin': {
      return (
        <LinkedinShareButton
          url={`https://rasmusolsson.dev${slug}`}
          title={title}
          style={shareButtonStyle}
        >
          <LinkedinIcon
            size={iconSize}
            round
            iconFillColor="#bbb"
            bgStyle={iconStyle}
            borderRadius={1}
          ></LinkedinIcon>
        </LinkedinShareButton>
      );
    }
    case 'email': {
      return (
        <EmailShareButton
          url={`https://rasmusolsson.dev${slug}`}
          title={title}
          style={shareButtonStyle}
        >
          <EmailIcon
            size={iconSize}
            round
            iconFillColor="#bbb"
            bgStyle={iconStyle}
            borderRadius={1}
          ></EmailIcon>
        </EmailShareButton>
      );
    }
    case 'reddit': {
      return (
        <RedditShareButton
          url={`https://rasmusolsson.dev${slug}`}
          title={title}
          style={shareButtonStyle}
        >
          <RedditIcon
            size={iconSize}
            round
            iconFillColor="#bbb"
            bgStyle={iconStyle}
            borderRadius={1}
          ></RedditIcon>
        </RedditShareButton>
      );
    }
    case 'whatsapp': {
      return (
        <WhatsappShareButton
          url={`https://rasmusolsson.dev${slug}`}
          title={title}
          style={shareButtonStyle}
        >
          <WhatsappIcon
            size={iconSize}
            round
            iconFillColor="#bbb"
            bgStyle={iconStyle}
            borderRadius={1}
          ></WhatsappIcon>
        </WhatsappShareButton>
      );
    }
    case 'telegram': {
      return (
        <TelegramShareButton
          url={`https://rasmusolsson.dev${slug}`}
          title={title}
          style={shareButtonStyle}
        >
          <TelegramIcon
            size={iconSize}
            round
            iconFillColor="#bbb"
            bgStyle={iconStyle}
            borderRadius={1}
          ></TelegramIcon>
        </TelegramShareButton>
      );
    }
    case 'viber': {
      return (
        <ViberShareButton
          url={`https://rasmusolsson.dev${slug}`}
          title={title}
          style={shareButtonStyle}
        >
          <ViberIcon
            size={iconSize}
            round
            iconFillColor="#bbb"
            bgStyle={iconStyle}
            borderRadius={1}
          ></ViberIcon>
        </ViberShareButton>
      );
    }
    case 'weibo': {
      return (
        <WeiboShareButton
          url={`https://rasmusolsson.dev${slug}`}
          title={title}
          style={shareButtonStyle}
        >
          <WeiboIcon
            size={iconSize}
            round
            iconFillColor="#bbb"
            bgStyle={iconStyle}
            borderRadius={1}
          ></WeiboIcon>
        </WeiboShareButton>
      );
    }
    case 'hatena': {
      return (
        <HatenaShareButton
          url={`https://rasmusolsson.dev${slug}`}
          title={title}
          style={shareButtonStyle}
        >
          <HatenaIcon
            size={iconSize}
            round
            iconFillColor="#bbb"
            bgStyle={iconStyle}
            borderRadius={1}
          ></HatenaIcon>
        </HatenaShareButton>
      );
    }
    case 'line': {
      return (
        <LineShareButton
          url={`https://rasmusolsson.dev${slug}`}
          title={title}
          style={shareButtonStyle}
        >
          <LineIcon
            size={iconSize}
            round
            iconFillColor="#bbb"
            bgStyle={iconStyle}
            borderRadius={1}
          ></LineIcon>
        </LineShareButton>
      );
    }
    case 'mailru': {
      return (
        <MailruShareButton
          url={`https://rasmusolsson.dev${slug}`}
          title={title}
          style={shareButtonStyle}
        >
          <MailruIcon
            size={iconSize}
            round
            iconFillColor="#bbb"
            bgStyle={iconStyle}
            borderRadius={1}
          ></MailruIcon>
        </MailruShareButton>
      );
    }
    case 'tumblr': {
      return (
        <TumblrShareButton
          url={`https://rasmusolsson.dev${slug}`}
          title={title}
          style={shareButtonStyle}
        >
          <TumblrIcon
            size={iconSize}
            round
            iconFillColor="#bbb"
            bgStyle={iconStyle}
            borderRadius={1}
          ></TumblrIcon>
        </TumblrShareButton>
      );
    }
    case 'pocket': {
      return (
        <PocketShareButton
          url={`https://rasmusolsson.dev${slug}`}
          title={title}
          style={shareButtonStyle}
        >
          <PocketIcon
            size={iconSize}
            round
            iconFillColor="#bbb"
            bgStyle={iconStyle}
            borderRadius={1}
          ></PocketIcon>
        </PocketShareButton>
      );
    }
    case 'instapaper': {
      return (
        <InstapaperShareButton
          url={`https://rasmusolsson.dev${slug}`}
          title={title}
          style={shareButtonStyle}
        >
          <InstapaperIcon
            size={iconSize}
            round
            iconFillColor="#bbb"
            bgStyle={iconStyle}
            borderRadius={1}
          ></InstapaperIcon>
        </InstapaperShareButton>
      );
    }
    case 'workplace': {
      return (
        <WorkplaceShareButton
          url={`https://rasmusolsson.dev${slug}`}
          title={title}
          style={shareButtonStyle}
        >
          <WorkplaceIcon
            size={iconSize}
            round
            iconFillColor="#bbb"
            bgStyle={iconStyle}
            borderRadius={1}
          ></WorkplaceIcon>
        </WorkplaceShareButton>
      );
    }
    case 'livejournal': {
      return (
        <LivejournalShareButton
          url={`https://rasmusolsson.dev${slug}`}
          title={title}
          style={shareButtonStyle}
        >
          <LivejournalIcon
            size={iconSize}
            round
            iconFillColor="#bbb"
            bgStyle={iconStyle}
            borderRadius={1}
          ></LivejournalIcon>
        </LivejournalShareButton>
      );
    }

    default: {
      return <div></div>;
    }
  }
};
