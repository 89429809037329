import { faClock } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import {
  container,
  dateFormat,
  header,
} from './related-post.module.css';

export const RelatedPost: React.FC<{
  slug: string;
  date: Date;
  title: string;
  featuredImage: any;
}> = ({ slug, date, title, featuredImage }) => {
  return (
    <div className={container}>
      {featuredImage !== undefined ? (
        <Img fixed={featuredImage} />
      ) : null}
      <div className={header}>
        <Link to={slug}>{title}</Link>
      </div>
      <div className={dateFormat}>
        <div style={{ marginRight: '5px' }}>
          <FontAwesomeIcon color="#929292" icon={faClock} />
        </div>
        <div>{date}</div>
      </div>
    </div>
  );
};
