import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { RelatedPost } from './related-post/related-post';
import {
  angleRight,
  container,
  header,
  post,
} from './related-posts.module.css';

export const RelatedPosts: React.FC<{
  tags: string[];
  slug: string;
  postData: any;
}> = ({ tags, slug, postData }) => {
  const posts = filterPosts(postData, slug, tags);

  const relatedPosts = Object.values(posts).map((post: any, i) => {
    return (
      <RelatedPost
        key={post.slug}
        slug={post.slug}
        date={post.date}
        title={post.title}
        featuredImage={post.featuredImage}
      />
    );
  });

  return (
    <div className={container}>
      <div className={header}>
        <FontAwesomeIcon className={angleRight} icon={faAngleRight} />
        <span>You may also like</span>
      </div>
      <div className={post}>{relatedPosts}</div>
    </div>
  );
};

const filterPosts = (postData: any, slug: string, tags: string[]) => {
  postData = Object.values(postData).filter(
    (x: any) => x.slug !== slug,
  );

  const posts = Object.values(postData).filter((x: any) =>
    x.tags?.some((x: any) => tags.includes(x)),
  );

  const difference = postData.filter((x: any) => !posts.includes(x));

  let i = 0;
  while (posts.length < 3) {
    posts.push(difference[i]);
    i++;
  }

  while (posts.length > 3) {
    posts.pop();
  }

  return posts;
};
